import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ListsService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    uploadFile(args) {
      return axiosService.axiosIns.post(this.jwtConfig.uploadFile, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
    }

    getCounts(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getCounts, ...args)
    }

    getIpaTemplates(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getIpaTemplates, ...args)
    }

    getSelectedIpaTemplate(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getSelectedIpaTemplate, ...args)
    }
}

function useListsService() {
  const listsService = new ListsService()

  return {
    listsService,
  }
}

const { listsService } = useListsService()
export default listsService
